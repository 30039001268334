import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(0.000000, 0.000000)">
        <path
          d="M42.9566 53.5084V66.0195H37V34H46.7653C48.9501 34 50.8175 34.2288 52.3676 34.6864C53.9324 35.1293 55.2093 35.7567 56.1984 36.5686C57.2022 37.3806 57.933 38.3549 58.3906 39.4916C58.863 40.6135 59.0992 41.8536 59.0992 43.2117C59.0992 44.2893 58.9368 45.3079 58.612 46.2675C58.302 47.227 57.8444 48.098 57.2391 48.8804C56.6487 49.6628 55.9105 50.3493 55.0248 50.9398C54.1538 51.5303 53.1574 52.0027 52.0354 52.357C52.7883 52.7851 53.4379 53.3977 53.9841 54.1949L62 66.0195H56.6413C56.1246 66.0195 55.6817 65.9161 55.3127 65.7095C54.9584 65.5028 54.6557 65.2076 54.4048 64.8237L47.6732 54.5713C47.4222 54.1875 47.1417 53.9144 46.8317 53.752C46.5365 53.5896 46.0936 53.5084 45.5031 53.5084H42.9566ZM42.9566 49.2347H46.6767C47.7986 49.2347 48.773 49.0945 49.5996 48.814C50.4411 48.5335 51.1275 48.1497 51.659 47.6625C52.2052 47.1606 52.6112 46.5701 52.8769 45.8911C53.1426 45.212 53.2755 44.4665 53.2755 43.6546C53.2755 42.0307 52.7366 40.7833 51.659 39.9123C50.5961 39.0413 48.9649 38.6058 46.7653 38.6058H42.9566V49.2347Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
