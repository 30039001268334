import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M34.725 52.025V66.15H28V30H39.025C41.4917 30 43.6 30.2583 45.35 30.775C47.1167 31.275 48.5583 31.9833 49.675 32.9C50.8083 33.8167 51.6333 34.9167 52.15 36.2C52.6833 37.4667 52.95 38.8667 52.95 40.4C52.95 41.6167 52.7667 42.7667 52.4 43.85C52.05 44.9333 51.5333 45.9167 50.85 46.8C50.1833 47.6833 49.35 48.4583 48.35 49.125C47.3667 49.7917 46.2417 50.325 44.975 50.725C45.825 51.2083 46.5583 51.9 47.175 52.8L56.225 66.15H50.175C49.5917 66.15 49.0917 66.0333 48.675 65.8C48.275 65.5667 47.9333 65.2333 47.65 64.8L40.05 53.225C39.7667 52.7917 39.45 52.4833 39.1 52.3C38.7667 52.1167 38.2667 52.025 37.6 52.025H34.725ZM34.725 47.2H38.925C40.1917 47.2 41.2917 47.0417 42.225 46.725C43.175 46.4083 43.95 45.975 44.55 45.425C45.1667 44.8583 45.625 44.1917 45.925 43.425C46.225 42.6583 46.375 41.8167 46.375 40.9C46.375 39.0667 45.7667 37.6583 44.55 36.675C43.35 35.6917 41.5083 35.2 39.025 35.2H34.725V47.2Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
